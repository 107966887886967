import { createRouter, createWebHistory } from "vue-router";
import Login from "@/views/LoginPage.vue";
import Home from "@/views/HomePage.vue";
import Member from "@/views/MemberPage.vue";
// 移除未使用的 import
// import MainPage from "@/views/MainPage.vue";

const routes = [
  { path: "/", component: Home },
  { path: "/Member", component: Member },
  { path: "/Login", component: Login },
  {
    path: "/home",
    name: "Home",
    component: () => import("../views/HomePage.vue"),
  },
  {
    path: "/main",
    name: "Main",
    component: () => import("../views/PanelList.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
